// pages/_app.tsx
import { Fragment, useEffect } from "react";
import Head from "next/head";
import type { AppProps } from "next/app";
import { ThemeProvider, createTheme } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import "../assets/css/navbar.css";
import "../assets/css/font-awesome.min.css";
import "./global.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { appWithTranslation } from 'next-i18next';
import { CategoriesProvider } from "../context/CategoriesProvider";

function MyApp(props: AppProps) {
  const { Component, pageProps } = props;

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles && jssStyles.parentElement) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  const muiTheme = createTheme();

  return (
    <Fragment>
      <Head>
        <title>Spack plastics</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <meta name="description" content="Spack News" />
        <link rel="icon" href="/frame-2.svg" type="image/svg+xml" />

        <meta name="keywords" content="Spack plastics, packaging solutions, sustainable packaging, Vietnam, China, brand visibility, product protection, packaging innovation, packaging industry, one-stop packaging supplies" />
        <meta
          name="description"
          content="Vietnam is a new global hub in the packaging industry, and Spack is proud to be a leading producer. 
        With advanced factories in Vietnam and China, along with a skilled team, we deliver high-quality, sustainable packaging solutions tailored to your brand. 
        We recognize that impactful packaging is vital in today’s market, and we are committed to being your trusted partner, providing solutions that protect your products and elevate your brand's visibility.
        Explore our innovative offerings and discover how we can help your business stand out!"
        ></meta>
        <meta
          name="robots"
          content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large"
        ></meta>
        <link rel="canonical" href="https://spackplastics.com/"></link>
        <meta
          property="og:title"
          content="One-Stop Packaging Supplies"
        ></meta>
        <meta
          property="og:description"
          content="Vietnam is a new global hub in the packaging industry, and Spack is proud to be a leading producer. With advanced factories in Vietnam and China, along with a skilled team, we deliver high-quality, sustainable packaging solutions tailored to your brand. We recognize that impactful packaging is vital in today’s market, and we are committed to being your trusted partner, providing solutions that protect your products and elevate your brand's visibility.


Explore our innovative offerings and discover how we can help your business stand out!"
        ></meta>
        <meta property="og:url" content="https://spackplastics.com/"></meta>
        <meta property="og:site_name" content="spackplastics.com"></meta>
        <meta property="og:type" content="website"></meta>
        <link rel="shortcut icon" href="/frame-2.png" type="image/png" />
        <link rel="preconnect" href="https://spack-resource.nhuahvt.com" />
        <link rel="preconnect" href="https://www.google.com" />
        <link rel="preconnect" href="https://jnn-pa.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link rel="preconnect" href="https://googleads.g.doubleclick.net" />
        <link rel="preconnect" href="https://www.gstatic.com" />
        <link rel="preconnect" href="https://i.ytimg.com" />
      </Head>
      <ThemeProvider theme={muiTheme}>
        <CssBaseline />
        <CategoriesProvider> {/* Wrap với CategoriesProvider */}
          <Component {...pageProps} />
        </CategoriesProvider>
        <ToastContainer />
      </ThemeProvider>
    </Fragment>
  );
}

export default appWithTranslation(MyApp);