import axios from "axios";

const requestApi = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BASE_URL,
  timeout: 30000,
});

requestApi.interceptors.request.use((config) => {
  return config;
});

requestApi.interceptors.response.use(
  (res) => {
    return res;
  },
  async (error) => {
    if (error?.response) {
      if (error?.response?.status === 403) {
        throw error;
      } else {
        throw error;
      }
    }
  }
);
export default requestApi;
