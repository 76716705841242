import requestApi from "./requestApi";

export function get_articles(
  q = "",
  categoryid = "",
  order = "",
  page = 1,
  pagesize = 20
) {
  return requestApi.get("api/Article/GetArticles", {
    headers: {
      Accept: "application/json",
    },
    params: {
      q,
      categoryid,
      order,
      page,
      pagesize,
    },
  });
}
export function get_article_by_id(id = 0) {
  return requestApi.get("api/Article/GetArticle", {
    headers: {
      Accept: "application/json",
    },
    params: {
      id,
    },
  });
}

export function get_banners(position = 0) {
  return requestApi.get("api/Banner/GetBanners", {
    headers: {
      Accept: "application/json",
    },
    params: {
      position,
    },
  });
}

export function get_news_articles({
  q = "",
  order = "",
  page = 1,
  pagesize = 15,
}) {
  return requestApi.get("api/Article/GetNewsArticles", {
    headers: {
      Accept: "application/json",
    },
    params: {
      q,
      order,
      page,
      pagesize,
    },
  });
}

export function get_product(slug: any) {
  return requestApi.get("api/Product/GetProductBySlug", {
    headers: {
      Accept: "application/json",
    },
    params: { slug },
  });
}

export function get_news_article_by_slug(slug: any) {
  return requestApi.get("api/Article/GetArticleBySlug", {
    headers: {
      Accept: "application/json",
    },
    params: { slug },
  });
}

export function get_products({
  q = "",
  categoryid = "",
  attrValue = "",
  order = "",
  page = 1,
  pagesize = 12,
}) {
  return requestApi.get("api/Product/GetProducts", {
    headers: {
      Accept: "application/json",
    },
    params: {
      q,
      categoryid,
      attrValue,
      order,
      page,
      pagesize,
    },
  });
}

export function get_products_cate({
  q = "",
  categoryid = "",
  attrValue = "",
  order = "",
  page = 1,
  pagesize = 12,
}) {
  return requestApi.get("api/Product/GetCategoryWithProducts", {
    headers: {
      Accept: "application/json",
    },
    params: {
      q,
      categoryid,
      attrValue,
      order,
      page,
      pagesize,
    },
  });
}

export function get_category({ id = "" }) {
  return requestApi.get("api/Category/GetCategory", {
    headers: {
      Accept: "application/json",
    },
    params: {
      id,
    },
  });
}

export function get_categories({ entityType = 1 }) {
  return requestApi.get("api/Category/GetCategories?entityType=1", {
    headers: {
      Accept: "application/json",
    },
    params: {
      entityType,
    },
  });
}

export function create_contact(params = {}) {
  return requestApi.post("api/Shared/CreateContact", params, {
    headers: {
      Accept: "application/json",
    },
  });
}

export function get_linked_products(
  productid = "",
  productLinkType = 0,
  count = 6
) {
  return requestApi.get("/api/Product/GetLinkedProducts", {
    headers: {
      Accept: "application/json",
    },
    params: {
      productid,
      productLinkType,
      count,
    },
  });
}
