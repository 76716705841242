import React, { createContext, useEffect, useState, ReactNode } from 'react';
import { get_categories } from '../service/api';

export interface Category {
  id: number;
  title: string;
  slug: string;
}

interface CategoriesContextType {
  categories: Category[];
}

export const CategoriesContext = createContext<CategoriesContextType | undefined>(undefined);

export const CategoriesProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [categories, setCategories] = useState<Category[]>([]);

  useEffect(() => {
    const fetchCategories = async () => {
      const res = await get_categories({});
      setCategories(res?.data || []);
    };

    fetchCategories();
  }, []);

  return (
    <CategoriesContext.Provider value={{ categories }}>
      {children}
    </CategoriesContext.Provider>
  );
};